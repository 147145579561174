global.designby = "Designed by Bluehash ";

global.settings = [
    {
      place1 :'Place0: ',
      phone1 :1234567893,
      place2 :'Place2: ',
      phone2 :1234567893,
      place3 :'Place3: ',
      phone3 :1234567893,
      place4 :'Place4: ',
      phone4 :1234567893,
    }
    
    ];

global.homemeta = "gfgfgfgfg";
