import React, { useState } from 'react';
import Menu from './Menu';


import logo from "../../assets/images/logo.png";

let Header = (props) => {



  
    return (
      <div>
        <div className="row header-row">
           <div className="container header-con">

           <div className="row">
               
           <div className="col-lg-6 main-logo">
             <figure>
            <img src={logo} className="" alt="logo"/>
            </figure>
           </div>

           <div className="col-lg-6 phone-col">

           <h4>{props.place1} {props.phone1}</h4>
           <h4>{props.place2} {props.phone2}</h4>
           <h4>{props.place3} {props.phone3}</h4>

      

           </div>
           </div>

           </div>
        </div>

        <div className="row menu-row">
           <div className="container menu-con">
       
           <div className="col-md-12">
<Menu/>
  
           </div>
           </div>
           </div>
           </div>
       
    );

    
};
export default Header;