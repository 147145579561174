import './css/style.css';
import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider } from "react-router-dom";
import Home from './pages/Home';
import About from './pages/About';
import RootLayout from './pages/Root';


const router = createBrowserRouter ([
  {
    path:'/',
    element:<RootLayout/>,
    children:[
      {path:'/', element:<Home/> },
      {path:'/About', element:<About/> },


    ]

  },


]);

function App() {
  return (
    <>



    <RouterProvider router={router}/>
    
    
       
        </>
  );
}

export default App;
