import Header from "../components/Common/Header";
import Carouselinner from "../components/carousel/Carouselinner";


import Footer from "../components/Common/Footer";
import Aboutcontent from "../components/Aboutcontent";
import { Helmet } from "react-helmet";
import aboutimg from "../assets/images/banner/about.jpg";


import '../components/Settings.js';

const About = () => {
    return (
       <div>
          <Helmet>
                <title>About Title</title>
                <meta name="keywords" content="about "/>
                 <meta name="description" content="about description"/>
              
            </Helmet>
      <Header place1={global.settings[0].place1} phone1={global.settings[0].phone1} 
        place2={global.settings[0].place2} phone2={global.settings[0].phone2}        
        place3={global.settings[0].place3} phone3={global.settings[0].phone3}></Header>

     <Carouselinner  img1 = {aboutimg}></Carouselinner>

 

      <Aboutcontent/>


       
        <Footer></Footer>
        </div>
    );
}
export default About;
