import '../Settings.js';
let Footer = () => {

    return(
        <>
        
        <div className="row footer-row">

        <div className="container  footer-con">
            
           {global.designby}
            
            </div>
            
            </div>
        
        </>  


    );

};
export default Footer;