import React from 'react';
import QuickQuoteForm from './QuickQuoteForm';

const QuickRoot = () =>{

return (
    <div className="row quickRow">

            <div className="container quickcon">

<QuickQuoteForm>

</QuickQuoteForm>

                </div>

    </div>


);

};

export default QuickRoot;