

let Carouselinner = (props) => {


 
    return(
    
    
    
        <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
 
        <div class="carousel-inner">
          <div class="carousel-item active">
          <img src= {props.img1} className="main-logo" alt=""/>
          </div>
     
        
        </div>
   
      </div>
   
    
    
    
    
    );
    
    }
    
    export default Carouselinner;