import React, { useRef } from 'react';


const QuickQuoteForm = () => {






return (

  

<div>
<form id="form" name="form" action="mailer.php" method="post" >

    <div className="row">
    
        <div className="col-lg-9 col-sm-9">

      <input type="text" name="name" placeholder='Name' />


        </div>

        <div className="col-lg-3 col-sm-3">
        <input type="hidden" name="recaptcha_response" id="recaptchaResponse" />
        <button type="submit">Submit</button>
            
        </div>

        </div>
    </form>

</div>


);

    





};

export default QuickQuoteForm;