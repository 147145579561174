import Header from "../components/Common/Header";
import Carousel from "../components/carousel/Carousel";
import QuickRoot from "../components/Form/QuickRoot";
import Welcome from "../components/Welcome";
import Footer from "../components/Common/Footer";
import '../components/Settings.js';

import { Helmet } from 'react-helmet';

import aboutbanner from "../assets/images/banner/about.jpg";

import homebanner from "../assets/images/banner/home.jpg";

const Home = () => {







    return (

    
        <div>

      <Helmet>
        <title>Home Page</title>
        <meta name="description"  content="home page content"/>
        <meta name="keywords" content=""></meta>
       
      </Helmet>
          
        <Header place1={global.settings[0].place1} phone1={global.settings[0].phone1} 
        place2={global.settings[0].place2} phone2={global.settings[0].phone2}        
        place3={global.settings[0].place3} phone3={global.settings[0].phone3}>




        </Header>

    <Carousel img1 = {homebanner}  img2 = {aboutbanner}></Carousel>
    <QuickRoot></QuickRoot>
    <Welcome></Welcome>
    


       
        <Footer></Footer>
       
        </div>
        

    );
}
export default Home;
