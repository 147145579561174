
import { Outlet } from 'react-router-dom';

function RootLayout() {
    return (

        <>

      
        <Outlet/>
        
        </>
    )

}
export default  RootLayout;