
let 
Aboutcontent = () => {

return (

    
<>
<div className="row welcome-row">
    <div className="container welcome-con">

            <div className="row">

            <div className="col-lg-12">

                <h1>About us</h1>
            
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris maximus, dolor ac blandit cursus, felis turpis aliquet nulla, et commodo justo tortor vitae ipsum. In luctus id elit aliquet aliquet. Sed a consequat felis. Cras eget diam pharetra, pharetra massa eu, aliquam dolor. Vivamus id eleifend ex. Maecenas eget dolor eget justo rhoncus gravida vitae sit amet neque. Interdum et malesuada fames ac ante ipsum primis in faucibus. In in commodo nisl, et faucibus felis. Donec imperdiet facilisis risus ut cursus. Proin nec erat et sapien elementum vestibulum ut eget eros.</p>

            <p>Nam pellentesque tellus velit, id lobortis dui eleifend ac. Morbi varius sem ut quam vestibulum ultrices. Nullam sit amet magna volutpat, porttitor massa tincidunt, tempus ex. Donec luctus vehicula sollicitudin. Nam at nisl tellus. Morbi vitae lectus euismod, pellentesque nulla rhoncus, tempus dolor. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Nulla blandit ut erat vitae mattis. Integer commodo laoreet felis eget porttitor. Nullam vitae magna id ipsum viverra consectetur eget aliquet est. Proin ac erat consectetur magna suscipit gravida sit amet nec elit. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Phasellus accumsan est at dui ullamcorper finibus. Sed tincidunt libero nec tempus mattis.</p>
                

            </div>

                
            </div>

    </div>
</div>


</>

);

}

export default Aboutcontent ;